import React, {useEffect} from 'react';
import { Container, Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';


import "./OperatorsPage.css";
import api from "../../api/api";
import {PublicProfile} from "../public-profile";
import PreviousMessages from "./previous-messages/PreviousMessages";
import {negativeSentences, positiveSentences} from "./sentences-presets";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import LoginWindow from "../../login/LoginWindow";
import LogoutMessage from "../../login/LogoutMessage";
interface OperatorsPageProps {

}
const OperatorsPage: React.FC<OperatorsPageProps> = ({}) => {

    const [avatar, setAvatar] = React.useState<string>('');
    const [avatarOptions, setAvatarOptions] = React.useState<any>([]);
    const [scene, setScene] = React.useState<string>('Single scene (MVP)');


    const [mode, setMode] = React.useState<string>('Manual');


    const [message, setMessage] = React.useState<string>('');
    const [previousMessages, setPreviousMessages] = React.useState<string[]>([]);

    const [timeOfDayOptions, setTimeOfDayOptions] = React.useState<any>([]);
    const [weatherOptions, setWeatherOptions] = React.useState<any>([]);
    const [soundsOptions, setSoundsOptions] = React.useState<any>([]);
    const [emotionsOptions, setEmotionsOptions] = React.useState<any>([]);
    const [gesturesOptions, setGesturesOptions] = React.useState<any>([]);

    const [sessionsOptions, setSessionsOptions] = React.useState<any>([]);

    const [emotion, setEmotion] = React.useState<string>('neutral');
    const [gesture, setGesture] = React.useState<string>('idle');
    const [sound, setSound] = React.useState<string>('silence');
    const [timeOfDay, setTimeOfDay] = React.useState<string>("day");
    const [weather, setWeather] = React.useState<string>("sunshine");

    const [appliedAvatar, setAppliedAvatar] = React.useState<string>('');
    const [appliedSound, setAppliedSound] = React.useState<string>('');
    const [appliedTimeOfDay, setAppliedTimeOfDay] = React.useState<string>("");
    const [appliedWeather, setAppliedtWeather] = React.useState<string>("");
    const [appliedEmotion, setAppliedEmotion] = React.useState<string>("");

    const [username,setUsername] = React.useState<string>(localStorage.getItem("u")??"");
    const [authKey,setAuthKey] = React.useState<string>(localStorage.getItem("k")??"");

    let queryString = window.location.search;
    let queryParams = new URLSearchParams(queryString);
    // const roomNumber = queryParams.get('c') ?? "";
    // const roomNumber = queryParams.get('c') ?? generateBookingNumber();
    let { sessionId } = useParams<{ sessionId: string }>();
    let navigate = useNavigate();

    // const [room, setRoom] = React.useState<string>(sessionId??"");

    const addPreviousMessage = (previousMessage: string) => {
        setPreviousMessages([...previousMessages, previousMessage]);
    };
    const startNewSession = () => {
        setTimeout(async ()=>{
            const conversationResult = await api().post("conversations");
            navigate('/therapist/' + conversationResult.data._id);
        },1)
    }

    const avatarOptionById = (avatarId:string) => {
        for( let x=0; x< avatarOptions.length; x++) {
            if(avatarOptions[x]._id === avatarId || avatarOptions[x].username === avatarId) {
                return avatarOptions[x];
            }
        }
    }

    const loadOptionsFromApi = async (optionsName:string) => {
        const theOptions = await api().get("operator/" + optionsName);
        const data = theOptions.data;
        let resultingArray:any = [];
        for(let k in data) {
            if(typeof data[k] === "string") {
                resultingArray.push({key: k, value: data[k]})
            } else if(data[k].name) {
                resultingArray.push({key: k, value: data[k].name})
            }
        }
        return resultingArray;
    }
    const loadGesturesFromAPI = async () => {
        const options = await loadOptionsFromApi('gestures-options');
        const filteredOptions = options.filter((object:any,key:number) => {
            if(object.key !== "go-to-the-sakura-tree" &&
            object.key !== "go-to-the-window") {
                return object;
            }
        });
        return filteredOptions;
    }
    const loadSessionsFromApi = async () => {
        const theOptions = await api().get("conversations");
        const data = theOptions.data;
        setSessionsOptions(data);
    }
    useEffect(() => {
        if(!username || !authKey) {
            return;
        }
        setTimeout(async ()=>{
            if(!sessionId) {
                setTimeOfDayOptions(await loadSessionsFromApi());
            }
            const avatarOptionsData = await api().get("operator/avatars");

            setAvatarOptions(avatarOptionsData.data);
            if(avatarOptionsData.data[0]) {
                if(avatarOptionsData.data[0].username) {
                    setAvatar(avatarOptionsData.data[0].username);
                } else {
                    setAvatar(avatarOptionsData.data[0]._id);
                }
            }
            setTimeOfDayOptions(await loadOptionsFromApi('time-of-day-options'));
            setWeatherOptions(await loadOptionsFromApi('weather-options'));
            setSoundsOptions(await loadOptionsFromApi('sounds-options'));
            setEmotionsOptions(await loadOptionsFromApi('emotions-options'));
            setGesturesOptions(await loadGesturesFromAPI());

            if(sessionId) {
                const conversationObjectResult = await api().get('conversations/'+sessionId);
                if(conversationObjectResult.data) {
                    if(conversationObjectResult.data.environment) {
                        if(conversationObjectResult.data.environment.avatar) {
                            // console.log(avatar);
                            setAvatar(conversationObjectResult.data.environment.avatar);
                            setAppliedAvatar(conversationObjectResult.data.environment.avatar);
                        }
                        if(conversationObjectResult.data.environment.sound) {
                            setSound(conversationObjectResult.data.environment.sound);
                        }
                        if(conversationObjectResult.data.environment.timeOfDay) {
                            setTimeOfDay(conversationObjectResult.data.environment.timeOfDay);
                        }
                        if(conversationObjectResult.data.environment.weather) {
                            setWeather(conversationObjectResult.data.environment.weather);
                        }
                    }
                    if(conversationObjectResult.data.messages && conversationObjectResult.data.messages.length) {
                        setPreviousMessages(conversationObjectResult.data.messages);
                    }
                }
            }
        },1)
    },[sessionId, username, authKey])

    const sendMessage = (messageText:string, cleanUpInput = true) => {
        // let queryString = window.location.search;
        // let queryParams = new URLSearchParams(queryString);
        let messageToSend = messageText;

        if(emotion /*&& emotion != appliedEmotion*/) {
            if(!messageText.includes('>emotion:')) {
                messageToSend = `>emotion:${emotion}< ` + messageText;
                setAppliedEmotion(emotion);
            }
        }

        if(gesture !== "idle") {
            messageToSend = `>${gesture}< ` + messageText;
        }
        if(messageToSend.trim()) {
            api().post("operator/" + sessionId + "/say", {message: messageToSend});
            addPreviousMessage(messageToSend);
        }
        if(cleanUpInput) {
            setGesture("idle")
            setMessage("");
        }
    }
    const sendPositive= (message:string) => {
        // sendMessage(">emotion:empathy< >agree< " + message);
        sendMessage(">emotion:empathy< " + message);
    }
    const sendNegative = (message:string) => {
        // sendMessage(">emotion-change:angry< >spread-your-hands-for-a-moment< " + message);
        sendMessage(">emotion:angry< " + message);

    }
    const stopAll = () => {
        sendMessage(">action:stop-all<");
        setTimeout(()=>{
            //Twice just in case
            sendMessage(">action:stop-all<");
        },1000)
    }
    const exitVR = () => {
        sendMessage(">action:exit-vr<");
    }


    const applyEnvironmentChanges = () => {
        let combinedMessage = "";
        if(avatar && avatar != appliedAvatar) {
            combinedMessage +=` >clone:${avatar}<`
            setAppliedAvatar(avatar);
        }
        if(sound && sound != appliedSound) {
            combinedMessage +=` >sound:${sound}<`
            setAppliedSound(sound);
        }
        if(timeOfDay && timeOfDay != appliedTimeOfDay) {
            combinedMessage +=` >time-of-day:${timeOfDay}<`
            setAppliedTimeOfDay(timeOfDay);
        }
        if(weather && weather != appliedWeather) {
            combinedMessage +=` >weather:${weather}<`
            setAppliedtWeather(weather);
        }
        setTimeout(async ()=>{
            await api().patch("conversations/" + sessionId,
                {environment:
                        {
                            avatar: avatar,
                            sound: sound,
                            timeOfDay: timeOfDay,
                            weather: weather
                        }
                });
        },1);

        sendMessage(combinedMessage, false);

        /*
        const [appliedAvatar, setAppliedAvatar] = React.useState<string>('');
        const [appliedSound, setAppliedSound] = React.useState<string>('');
        const [appliedTimeOfDay, setAppliedTimeOfDay] = React.useState<string>("");
        const [appliedWeather, setAppliedtWeather] = React.useState<string>("");*/
        // if()
    }
    const getTimeFromNow = (timestamp:any) => {
        const momentDate = moment(timestamp);
        return momentDate.format('hh:mm - D MMM Y')
//
        // Calculate the time difference from now
        const fromNow = momentDate.fromNow();
        return fromNow;
    }
    const handleEnterKeyOnTextarea = (event:any) => {
        if (event.key === 'Enter' && !event.shiftKey && message.trim()) {
            event.preventDefault();
            sendMessage(message);
        }
    }
    return (
            !username ? <LoginWindow setUsername={setUsername} setAuthKey={setAuthKey}/>: sessionId ? <Container>
                <LogoutMessage setUsername={setUsername} setAuthKey={setAuthKey} username = {username} />
                <Box my={4}>
                    <Typography
                        variant="h3">{avatar ? `Avatar: ${avatarOptionById(avatar).name}` : 'Please choose avatar'} </Typography>
                    {/*<Typography variant="h5">Session status: in progress</Typography>*/}
                    <Grid container spacing={2} alignItems="center" textAlign={"left"}>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Avatar</InputLabel>
                                    <Select value={avatar} label={"Avatar"}
                                            onChange={(e) => setAvatar(e.target.value as string)}>
                                        {avatarOptions ?
                                            avatarOptions.map((option: PublicProfile, index: number) => {
                                                return <MenuItem value={option.username ? option.username : option._id}
                                                                 key={index}>{option.name}</MenuItem>
                                            })
                                            : <MenuItem value="">Loading, please wait</MenuItem>}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Scene</InputLabel>
                                    <Select label={"Scene"} disabled={true} value={scene}
                                            onChange={(e) => setScene(e.target.value as string)}>
                                        <MenuItem value="Single scene (MVP)">Single scene (MVP)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Time Of Day</InputLabel>
                                    <Select label={"Environment"} value={timeOfDay}
                                            onChange={(e) => setTimeOfDay(e.target.value as string)}>
                                        {timeOfDayOptions.map((object: any, index: number) => {
                                            return <MenuItem key={index} value={object.key}>{object.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Weather</InputLabel>
                                    <Select label={"Weather"} value={weather}
                                            onChange={(e) => setWeather(e.target.value as string)}>
                                        {weatherOptions.map((object: any, index: number) => {
                                            return <MenuItem key={index} value={object.key}>{object.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Music / Sound</InputLabel>
                                    <Select label={"Music / Sound"} value={sound}
                                            onChange={(e) => setSound(e.target.value as string)}>
                                        {soundsOptions.map((object: any, index: number) => {
                                            return <MenuItem key={index} value={object.key}>{object.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} md={2}>*/}
                            {/*    <Button variant="contained" color="primary" fullWidth>Play</Button>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Mode</InputLabel>
                                    <Select label={"Mode"} disabled={true} value={mode}
                                            onChange={(e) => setMode(e.target.value as string)}>
                                        <MenuItem value="Manual">Manual</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button variant="contained" color="primary" onClick={applyEnvironmentChanges}
                                        fullWidth>Apply</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>{avatar &&
                            <div style={{width: "500px", overflow: "hidden", marginLeft: "30px", fontSize: "1.2em"}}>

                                {appliedAvatar && <>
                                    <div style={{fontSize: "0.9em"}}> Link to Join for the viewer:</div>
                                    <br/>
                                    <a href={`/client/${sessionId}`}
                                       target={"_blank"}>{document.location.hostname + `/client/${sessionId}`}</a>
                                </>
                                }
                                <img src={avatarOptionById(avatar).avatarImage.url} width={800}
                                     style={{position: "relative", right: "200px"}}/>
                            </div>
                        }
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" style={{display: appliedAvatar ? "flex" : "none"}}>
                        <Grid item xs={12} md={6}>
                            {/* <TextField
                            label="Your message"
                            variant="outlined"
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            margin="normal"
                        />*/}
                            <Grid container spacing={2} alignItems="left" textAlign={"left"} style={{marginTop: "5px"}}>
                                <Grid item xs={6}>
                                    {positiveSentences.map((item, index) => {
                                        return <div onClick={() => {
                                            sendPositive(item)
                                        }} style={{cursor: "pointer"}} key={index}
                                                    className={"presetSentence positive pointer"}> {item} </div>
                                    })}
                                </Grid>
                                <Grid item xs={6}>
                                    {negativeSentences.map((item, index) => {
                                        return <div onClick={() => {
                                            sendNegative(item)
                                        }} style={{cursor: "pointer"}} key={index}
                                                    className={"presetSentence negative pointer"}> {item} </div>
                                    })}
                                </Grid>
                            </Grid>
                            <form onSubmit={(event: any) => {
                                event.preventDefault();
                                sendMessage(message);
                            }}>
                                <TextField
                                    label="Your message"
                                    variant="outlined"
                                    fullWidth
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    margin="normal"
                                    size="small"
                                    multiline
                                    rows={4}
                                    onKeyDown={handleEnterKeyOnTextarea}
                                />
                            </form>

                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Choose emotion</InputLabel>
                                        <Select label={"Choose emotion"} value={emotion}
                                                onChange={(e) => setEmotion(e.target.value as string)}>
                                            {emotionsOptions.map((object: any, index: number) => {
                                                return <MenuItem key={index}
                                                                 value={object.key}>{object.value}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Gesture</InputLabel>
                                        <Select value={gesture} label={"Gesture"}
                                                onChange={(e) => setGesture(e.target.value as string)}>
                                            {gesturesOptions.map((object: any, index: number) => {
                                                return <MenuItem key={index}
                                                                 value={object.key}>{object.value}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <PreviousMessages messages={previousMessages}/>
                    </Grid>


                    <Box mt={2} style={{display: appliedAvatar ? "flex" : "none"}}>
                        <Button variant="contained" color="success" fullWidth onClick={() => {
                            sendMessage(message)
                        }}>Submit</Button>
                    </Box>

                    <Box mt={2} style={{display: appliedAvatar ? "flex" : "none"}}>
                        <Button variant="outlined" color="error" fullWidth onClick={() => {
                            exitVR()
                        }}>Send Exit VR signal</Button>
                    </Box>

                    <Box mt={2} style={{display: appliedAvatar ? "flex" : "none"}}>
                        <Button variant="contained" color="error" fullWidth onClick={() => {
                            stopAll()
                        }}>Stop all animations and sounds</Button>
                    </Box>
                </Box>
            </Container>
        :
                <>
                    <LogoutMessage setUsername={setUsername} setAuthKey={setAuthKey} username={username} />
                    <Container>
                    <div style={{width:"300px", marginLeft:"30vw", marginTop:"20vh"}}>
                        <Box mt={3} style={{ textAlign:"center"}}>
                        Please choose previous session
                            <br />
                            <br />
                        <form onSubmit={
                            () => {

                            }
                        }>
                            <button onClick={
                                () => {

                                }
                            }>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Previous sessions</InputLabel>
                                    <Select value={gesture} label={"Previous sessions"} sx={{width:"280px"}}
                                            onChange={(e) => navigate("/therapist/" + (e.target.value as string))}>
                                        {sessionsOptions.map((object: any, index: number) => {
                                            return <MenuItem key={index}
                                                             value={object._id}>{getTimeFromNow(object.updatedAt)}: {object.environment?.avatar ? object.environment.avatar : 'No avatar'}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </button>
                        </form>
                        </Box>
                        <Box mt={3} >
                            OR
                            <br />
                            <br />
                            <Button variant="contained" color="success" fullWidth onClick={() => {
                                startNewSession()
                            }}>Start new session</Button>
                        </Box>
                    </div>
            </Container>
        </>
    );

}
export default OperatorsPage;
