import React, {useEffect} from 'react';
import { Container, Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';

import "./PreviousMessages.css";
interface PreviousMessagesProps {
    messages: string[];
}

const PreviousMessages: React.FC<PreviousMessagesProps> = ({messages}) => {
    const parseMessage = (message:string) => {
        // Split the message into parts using regex to capture commands and the main text
        const parts = message.split(/(>.*?<)/);

        return parts.map((part:string, index:number) => {
            // If the part matches the command pattern, create a div with the className "actions-command"
            if (part.startsWith('>') && part.endsWith('<')) {
                return (
                    <div className="actions-command" key={index}>
                        {part.slice(1, -1)}
                    </div>
                );
            }
            // Otherwise, just return the text part
            return part;
        });
    };

    return  <Grid item xs={12} md={6}>
        {/*<Typography variant="h6">Conversations log</Typography>*/}
        <Box p={2} borderColor="grey.400" className={"previousMessagesContainer"}>
            {!messages.length ? <Typography variant="body1">No previous messages yet</Typography> :
                <Typography variant="body1">
                    {messages.map((message, index:number) => (
                        <div className={"previousMessage " + "message-number-" + index} style={{textAlign:"left"}} key={index}>{parseMessage(messages[messages.length - index - 1])}</div>
                    ))}
                </Typography>
            }
        </Box>
    </Grid>
}
export default PreviousMessages;
