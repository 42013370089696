export const positiveSentences = [
    "I hear you and agree with you",
    "I fully understand you",
    "I appreciate you and all that you have done",
    "I support you what ever you do",
    "I love you very much",
    "Interested"
]

export const negativeSentences = [
    "I disagree with you",
    "What you are saying is stupid",
    "I don't believe you",
    "I dont trust you",
    "Stop dwelling on this issue",
    "I am not interested in having this conversation",
    "I don't love you",
    "I hate you",
    "You are fired",
    "I am leaving you"
];
