import React from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Grid, Typography, Container, Box } from '@mui/material';
import api, {reloadApiConfig} from "../api/api";

interface IFormInput {
    username: string;
    password: string;
}
interface LoginWindowProps {
    setUsername:any;
    setAuthKey:any;
}
const LoginWindow: React.FC<LoginWindowProps> = ({setUsername, setAuthKey}) => {
    const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = data => {
        setTimeout(async ()=>{
            const authKeyResult = await api().post("operator/login",{username:data.username.trim(), password:data.password});
            const authKey = authKeyResult.data;
            if(authKey) {
                localStorage.setItem('u', data.username.trim());
                localStorage.setItem('k', authKey);
                reloadApiConfig(data.username.trim()+'|'+authKey);
                setUsername(data.username.trim());
                setAuthKey(authKey);
            } else {
                alert('Wrong login or password');
            }
        },1)
    };
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="username"
                        label="Login"
                        autoComplete="username"
                        autoFocus
                        {...register('username', { required: 'Login is required'})}
                        error={!!errors.username}
                        helperText={errors.username ? errors.username.message : ''}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="password"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        {...register('password', { required: 'Password is required' })}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ''}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default LoginWindow;