import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import OperatorsPage from "./components/operator/operators-page/OperatorsPage";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ViewerSession from "./components/operator/viewers-page/ViewerSession";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
      <Router>
        <div className="App">
            <ToastContainer/>
            <Routes>
                <Route path="/therapist" element={ <OperatorsPage /> }/>
                <Route path="/therapist/:sessionId" element={ <OperatorsPage /> }/>
                <Route path="/" element={<ViewerSession />}/>
                <Route path="/client/:sessionId" element={<ViewerSession />}/>
            </Routes>
        </div>
      </Router>
  );
}

export default App;
