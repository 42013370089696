import React from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Grid, Typography, Container, Box } from '@mui/material';
import api, {reloadApiConfig} from "../api/api";

interface LogoutMessageProps {
    setUsername:any;
    setAuthKey:any;
    username:string;
}
const LogoutMessage: React.FC<LogoutMessageProps> = ({setUsername,username,setAuthKey}) => {
    const logoutAction = () => {
        localStorage.setItem('u','');
        localStorage.setItem('k','');
        reloadApiConfig("");
        setAuthKey("");
        setUsername("");
    }
    return <div style={{position:"absolute", right:"15px", top: "5px"}}>
        <span>{username} <a href={"#"} onClick={logoutAction}>logout</a></span>
    </div>
}
export default LogoutMessage;