import React, {useEffect, useRef, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import api from "../../api/api";
import "./ViewersSession.css";

import {toast} from "react-toastify";

interface ViewerSessionProps {

}
const ViewerSession: React.FC<ViewerSessionProps> = ({}) => {
    let { sessionId } = useParams<{ sessionId: string }>();
    // let { clone } = useParams<{ clone: string }>();
    const sessionInput = useRef<any>(null);
    const [currentSession, setCurrentSession] = useState(sessionId);
    const [currentAvatar, setCurrentAvatar] = useState("");
    let navigate = useNavigate();

    const startSession = (event:any) => {
        event.preventDefault();
        if(sessionInput.current?.value) {
            setTimeout(async ()=>{
                try {
                    const conversationResult = await api().get('conversations/' + sessionInput.current.value);
                    if(!conversationResult || !conversationResult.data) {
                        toast.error("VR session " + sessionInput.current?.value + " does not exist");
                    } else {
                        navigate('/client/' + sessionInput.current?.value);
                        setCurrentSession(sessionInput.current?.value);
                    }
                }catch(err) {
                    toast.error("VR session " + sessionInput.current?.value + " does not exist");
                }
            },1)
        }
    }
    useEffect(()=>{
        setTimeout(async ()=>{
            if(currentSession) {
                const conversationResult = await api().get('conversations/' + currentSession);
                if(conversationResult && conversationResult.data && conversationResult.data.environment.avatar) {
                    setCurrentAvatar(conversationResult.data.environment.avatar);
                }
            }
        },1)
    },[currentSession])
    useEffect(()=>{
        document.body.style.overflow="hidden";
        return () => {
            document.body.style.overflow = "";
        };
    },[]);
    return !currentSession ? <>

            <div style={{marginTop:"40vh"}}>Please enter session ID</div>
        <form onSubmit={startSession}>
        <input type={"text"} ref={sessionInput}/>

            <button onClick={startSession}>
                Start session
            </button>
        </form>
        </>
        :<>
            {currentAvatar && <iframe className={"viewer-session-iframe"} allow="xr-spatial-tracking" src={process.env.REACT_APP_ETERNITY_URL + "/SmartTherapy?clone="+currentAvatar+"&c="+currentSession}></iframe> }
    </>
}
export default ViewerSession;
